import { Injectable } from '@angular/core';
import { IMG_ADD_FILE_SVG, IMG_ICON_COLUMN_CHOOSER, IMG_ICON_REFRESH, IMG_ICON_XLSXFILEPOPUPIX } from '@app/constants-enums/constants';

@Injectable({
	providedIn: 'root'
})
export class UserManagementService {
	private selectedTabDetails: any;
	private isEditModeRoleMgmt: boolean;
	private roleID: any;
	private roleData: any;
	private listOfRoles: any[];
	private currentTab: any;
	private storedFilters: any;
	private uploadNewUsersDialogColumnList: any;
	constructor() {

	}

	setTabSelection(val: any) {
		this.selectedTabDetails = val;
	}
	getTabSelection() {
		return this.selectedTabDetails || undefined;
	}

	setRoleID(val: any) {
		this.roleID = val;
	}
	getRoleID() {
		return this.roleID || undefined;
	}

	setEditModeRoleManagement(val: any) {
		this.isEditModeRoleMgmt = val;
	}
	getEditModeRoleManagement() {
		return this.isEditModeRoleMgmt || undefined;
	}

	setRoleData(val: any) {
		this.roleData = val;
	}
	getRoleData() {
		return this.roleData || undefined;
	}

	setRoleManagementList(val: any) {
		this.listOfRoles = val;
	}
	getRoleManagementList() {
		return this.listOfRoles || [];
	}

	setCurrentTab(val: any) {
		this.currentTab = val;
	}
	getCurrentTab() {
		return this.currentTab || undefined;
	}

	public setStoredFilters(val: any) {
		this.storedFilters = val;
	}

	public getStoredFilters() {
		return this.storedFilters || undefined;
	}

	public getListOfNotifyBy() {
		return [
			{
				text: 'Email',
				value: 'email',
				visible: true,
			},
			{
				text: 'SMS',
				value: 'sms',
				visible: true,
			},
			{
				text: 'Push Alert',
				value: 'push_alert',
				visible: false,
			}
		];
	}

	public getListOfNotifyNoOfDays() {
		return [
			{
				text: 'Sunday',
				sort_text: 'Sun',
				value: 'sunday'
			},
			{
				text: 'Monday',
				sort_text: 'Mon',
				value: 'monday'
			},
			{
				text: 'Tuesday',
				sort_text: 'Tue',
				value: 'tuesday'
			},
			{
				text: 'Wednesday',
				sort_text: 'Wed',
				value: 'wednesday'
			},
			{
				text: 'Thursday',
				sort_text: 'Thu',
				value: 'thursday'
			},
			{
				text: 'Friday',
				sort_text: 'Fri',
				value: 'friday'
			},
			{
				text: 'Saturday',
				sort_text: 'Sat',
				value: 'saturday'
			}
		];
	}

	public getListOfNotifyDuration() {
		return [
			{
				text: 'Minutes',
				sort_text: 'Min(s)',
				value: 'minutes'
			},
			{
				text: 'Hours',
				sort_text: 'Hr(s)',
				value: 'hours'
			}
		];
	}

	// Method used to get Output : 10:10 AM | PM
	public doConvertTimeIntoAmPm(date): string {
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? 'PM' : 'AM';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		minutes = minutes < 10 ? '0' + minutes : minutes;
		const strTime = hours + ':' + minutes + ' ' + ampm;
		return strTime;
	}

	// Method used to get Output : 10:10 AM | PM
	public doConvertToDateTime(timeWithAmPm) {
		const newDate = new Date();
		const splitBySpace = timeWithAmPm.split(' ');
		if (splitBySpace && splitBySpace.length > 0) {
			const splitByColon = splitBySpace[0].split(':');
			if (splitByColon && splitByColon.length > 0) {
				let hours = +splitByColon[0];
				const minutes = +splitByColon[1];
				newDate.setHours(hours);
				newDate.setMinutes(minutes);
				newDate.setSeconds(0);
				if (splitBySpace[1] === 'AM') {
					if (hours === 12) {
						hours = hours - 12;
						newDate.setHours(hours);
					}
				}
				if (splitBySpace[1] === 'PM') {
					if (hours < 12) {
						hours = hours + 12;
						newDate.setHours(hours);
					}
				}
			}
		}
		return newDate;
	}

	public getUploadNewUsersDialogColumnList() {
		this.uploadNewUsersDialogColumnList = [
			{ text: 'First Name', value: 'first_name', required: true },
			{ text: 'Last Name', value: 'last_name', required: true },
			{ text: 'Email', value: 'email', required: true },
			{ text: 'Country Code', value: 'country_code', required: true },
			{ text: 'Mobile No.', value: 'mobile_number', required: true },
			{ text: 'Role', value: 'user_role', required: true },
			{ text: 'Sales Rep', value: 'sales_rep_from_erp', required: false },
			{ text: 'GL Account No.', value: 'gl_account_no', required: false },
			{ text: 'Vendor', value: 'vendor', required: false },
			{ text: 'CRM Data', value: 'crm_data', required: false },
			{ text: 'Contract Template', value: 'contract_template', required: false },
			{ text: 'Contract Start Date', value: 'contract_start_date', required: false },
			{ text: 'Contract End Date', value: 'contract_end_date', required: false }
		]
		return this.uploadNewUsersDialogColumnList;
	}

	public getListOfActionsForUserManagement() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add New',
				btn_image: IMG_ADD_FILE_SVG,
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'IMPORT',
				btn_name: 'Import User(s)',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'COLUMN_CHOOSER',
				btn_name: 'Column Chooser',
				btn_image: 'column-chooser',
				icon: IMG_ICON_COLUMN_CHOOSER,
				visible: true,
				disabled: false,
			}
		];
	}

	public getListOfActionsForBillingInvoice() {
		return [
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'EXPORT',
				btn_name: 'Export',
				btn_image: 'xlsxfile',
				icon: IMG_ICON_XLSXFILEPOPUPIX,
				visible: true,
				disabled: false,
			},
		];
	}

	public getListOfActionsForCardPaymentList() {
		return [
			{
				btn_code: 'ADD',
				btn_name: 'Add Payment Methods',
				btn_image: 'lsxfile',
				icon: ' ' + IMG_ADD_FILE_SVG,
				visible: true,
				disabled: false,
			},
			{
				btn_code: 'REFRESH',
				btn_name: 'Refresh',
				btn_mage: '',
				icon: IMG_ICON_REFRESH,
				visible: true,
				disabled: false,
			},
		];
	}
}
