import 'zone.js/dist/zone-error';
// const serverUrl = 'https://hmswin.webair.com:444/';
// const serverUrl = 'https://dev.incentx.com/taralCode/';
const serverUrl = 'https://staging.incentx.com/backend/';

export const environment = {
	production: true,
	validationMode: false, // Authorize.net : Live Mode /Test Mode
	URL_SERVER: serverUrl,
	URL_LOGIN: serverUrl + 'pwdUtil.php',
	URL_DATAOUT: serverUrl + 'dataout.php',
	URL_DATAOUT_SIGNUP: serverUrl + 'dataout.php?getTierListing',
	URL_DATAIN: serverUrl + 'datain.php',
	URL_User_DATAIN: serverUrl + 'userdatain.php',
	URL_CSVDATAIN: serverUrl + 'csvdatain.php',
	URL_USERSETUP: serverUrl + 'userSetup.php',
	URL_SUB_USER_SETUP: serverUrl + 'subUserSetup.php',
	URL_FORGOT_PASSWORD_SETUP: serverUrl + 'forgotPassword.php',
	URL_GET_DOCUMENT: serverUrl + 'getDocument.php',
	URL_GET_COUNTRY: './assets/JSON/country-data.json',
	URL_GET_STATE: './assets/JSON/state-data.json',
	URL_GET_PHONE_CODE: './assets/JSON/phone-code.json',
	URL_GET_NAME_DICTIONARY: './assets/JSON/name-dictionary.json',
	URL_TIMEZONE_JSON: './assets/JSON/timezone.json',
	URL_CDN_PROTOCOL: 'http://',
	URL_CDN_PROTOCOL_SECURE: 'https://',
	URL_CDN_PREFIX: 'cdn.',
	URL_CDN_DOMAIN: 'incentx.com',
	URL_CDN_POSTFIX: '',
	AUTH_PAY_CLIENT_KEY: '62p7Twx764AM8823mC99nsC3h8h57X7rmBVqkY2AcZkHfF9dAbA4UcU8fGWkmnVH',
	AUTH_PAY_TRASACTION_KEY: '6eA2Jr4JuG49394f',
	AUTH_PAY_API_LOGIN_ID: '7W9v3U9sQ',
	AUTH_PAY_SERVICE_URL: 'https://apitest.authorize.net/xml/v1/request.api',
	ACCEPT_JS_URL: 'https://jstest.authorize.net/v1/Accept.js',
	URL_WEBSITE_PRICING_PAGE: 'https://wpstaging.incentx.com/pricing/',
	URL_QB_ONLINE_CONNECT_URL: serverUrl + 'authorizationErp.php',
	URL_MSI: 'http://cdn.incentx.com/incentx-utility/incentXERPutility_dev.zip',
	URL_ERP_CONNECT_URL: serverUrl + 'authorizationErp.php',
	URL_CRM_CONNECT_URL: serverUrl + 'authorizationCrm.php',
	URL_ZOHO_BILLING_CONNECT_URL: serverUrl + 'authorizationZoho.php',
	URL_GET_DisposableEmail: serverUrl + 'assets/disposable-email.json?ver=',
	URL_GET_DisposableEmail_Local: './assets/JSON/disposable-email.json',
	URL_GET_SystemConfig: './assets/JSON/systemconfig.json?ver=',
	GOOGLE_RECAPTCHA_SITE_KEY: '6LfCj8AUAAAAAHJXZ00JxbkqgvOm_JgWDsF1mtJp',
	CARD_NOX_JS_URL: 'https://cdn.cardknox.com/ifields/2.14.2211.1101/ifields.min.js',
	CARD_NOX_HTML_URL: 'https://cdn.cardknox.com/ifields/2.14.2211.1101/ifield.htm',
	URL_DOCU_SIGN_CONNECT_URL: serverUrl + 'authorizationDocusign.php',
	URL_AUTHORIZATION_SSO: serverUrl + 'authorizationSSO.php',
	GET_CSV_DOC: serverUrl + 'getCsvDoc.php',
	SITE_24X7: false, // Tracking the portal
};
