export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: "YYYY-MM-DD"
	},
	display: {
		dateInput: "YYYY-MM-DD",
		monthYearLabel: "YYYY MM ",
		dateA11yLabel: "YYYY-MM-DD",
		monthYearA11yLabel: "YYYY MMMM"
	}
};

export const CUSTOM_MONTH_YEAR_FORMATS = {
	parse: {
		dateInput: "YYYY - MMMM"
	},
	display: {
		dateInput: 'YYYY - MMMM',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	}
};

export const MOUSE_MOVE_EXPIRE_RESET_TIME = 60;

export const DATE_FORMAT_BACKEND = "YYYY-MM-DD HH:mm:ss";
export const DATETIME_FORMAT_BACKEND = "YYYY-MM-DD";
export const DATETIME_FORMAT_FRONTEND = "DD MMM YYYY HH:mm";
export const DATE_FORMAT_FRONTEND = "DD MMM YYYY";
export const IMG_PROFILE_PIC = "./assets/images/default-user.png";
export const IMG_EDIT = "./assets/images/edit_icon.png";

export const IMG_EDIT_SVG = "icon-edit_menu";
export const IMG_MARK_AS_CUSTOMER_SVG = "icon-mark_as_customer";
export const IMG_MARK_AS_PROSPECT_SVG = "icon-prospects_menu";
export const IMG_MARK_AS_BUYING_GROUP_SVG = "icon-mark_as_buying_group";
export const IMG_CLOSE_SVG = "icon-delete";
export const IMG_ADD_TO_PIPELINE_SVG = "icon-pipeline";
export const IMG_MAIL_SVG = "icon-mail";
export const IMG_EMAIL_DX_SVG = "emaildxtable";
export const IMG_CALL_SE_SVG = "icon-call";
export const IMG_DOC_SVG = "icon-doc-se";
export const IMG_SITE_VISIT_SVG = "icon-site_visit_se";
export const IMG_APPOINTMENT_SE_SVG = "icon-appointment_se";
export const IMG_ADD_TASK_SE_SVG = "icon-add_task";
export const IMG_DEAL_SE_SVG = "icon-deal_se";
export const IMG_USR_IMG_DEFAULT = "./assets/images/usr-img-default.jpg";
export const IMG_ACTIONS_SVG = "icon-actions";
export const IMG_ADD_FILE_SVG = "icon-plus";
export const IMG_MARK_AS_CLOSED_SVG = "icon-mark_as_closed";
export const IMG_MARK_AS_OPEN_SVG = "icon-mark_as_open";
export const IMG_ADD_CHARGEBACKS_SVG = "icon-dollar";
export const IMG_ADD_ORDERS_SVG = "icon-clipboard_2";
export const IMG_ADD_REBATES_SVG = "icon-Rebates";
export const IMG_ADD_PAYMENTS_SVG = "icon-edit_payment";
export const IMG_ICON_HIDE_SVG = "icon-hide";
export const IMG_ICON_FULL_SCREEN_PURPLE_SVG = "icon-full-screen-purple";
export const IMG_ICON_REFRESH = "refresh";
export const IMG_ICON_XLSXFILEPOPUPIX = "xlsxfilepopupix";
export const IMG_ICON_EXPORT_PDF = "exportpdf";
export const IMG_ICON_COLUMN_CHOOSER = "columnChooser";
export const IMG_ICON_VERTICALALIGNCENTER = "verticalaligncenter";
export const IMG_ICON_MARK_AS_BUYING_GROUP = "icon-mark_as_buying_group";
export const IMG_ICON_CLOSE_MULTIPLE_SETTLEMENT = "closeMultipleSettlement";
export const IMG_ICON_UPDATE_CURRENT_SETTLEMENT = "convertorder";
export const IMG_ICON_VIEW_DETAILS = "viewDetails";

export const PAGE_SIZE_OPTIONS = [10, 25, 100];
export const PAGE_SIZE_OPTIONS_REPORT = [1000, 5000, 10000];
export const PAGE_SIZE = 10;

//Incentx Admin portal User
export const ADMIN_PORTAL = 'adminportal';
export const SUPER_ADMIN = 'superadmin';


// App State (AS) declaration
export const AS_USER_MANAGEMENT = {
	USER_DETAILS: "USER_DETAILS",
	ADD_EDIT_USER_STATUS: "ADD_EDIT_USER_STATUS"
};

export const AS_USER_GROUP = {
	USERGROUPS_DETAILS: "USERGROUPS_DETAILS",
	ADD_EDIT_USERGROUPS_STATUS: "ADD_EDIT_USERGROUPS_STATUS"
};

export const PROJECT_NAME = "incentX";
export const MSG_BILLTO_SHIPTO_ADDRESS_CONFIRM = "Do you want to add Billing & Shipping address?";
export const MSG_CONFIRMED_MESSAGE = "Are you sure you want to delete? You will not be able to recover them.";
export const CONFIRMED_DELETE_TITLE = "Delete";
export const MSG_ERROR_MESSAGE = "Something went wrong. Please try again later!";
export const MSG_SUBSCRIPTION_PLAN_STATUS = "Are you sure you want to change status?";
export const PREFERRED_COUNTRIES = ['us', 'ca'];
export const OUTSTANDING_INVOICE_MESSAGE = "You must pay outstanding invoices before you upgrade or change your plan.";
// export const RGX_URL_VALID = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?|^((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
// Above Regex valid for E.g:
/*
https://www.zone24x7.com
http://www.zone24x7.com
http://www.zone24x7.co.uk
http://192.168.1.1
www.zone24x7.com
*/

export const RGX_URL_VALID = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/g;
// Above Regex valid for E.g:
/*
https://www.zone24x7.com
http://www.zone24x7.com
http://www.zone24x7.co.uk
http://192.168.1.1
www.zone24x7.com
*/

export const RGX_EMAIL_VALID = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/;
export const RGX_REPLACE_SPACE = /\s/g; // return Removed all Space from any string
export const RGX_REPLACE_NUMBER_ONLY = /[^0-9]/g; // return only digits in string
export const RGX_REPLACE_NUMBER_WITH_DECIMAL_ONLY = /[^.0-9]/g; // return only digits with decimal in string

export const TAXJAR_ALLOWED_COUNTRIES = [
	{ "name": "United States", "code": "US" }
];

export const NO_OF_DAYS_FOR_TRIAL_USER = 14;

export const IxLoadText = 'Loading...';

export const ServerMethods = {
	SMS_OTP: 'smsOTP',
	VERIFY_OTP: 'verifyOTP',
	GET_ENTITY_DATA: 'getEntityData',
	DX_GET_ENTITY_DATA: 'dxGetEntityData',
	SAVE_USER_SETTINGS: 'saveUserSettings',
	DOWNLOAD_PDF: 'downloadPDF',
	COPY_CONTRACT: 'copyContract',
	DOWNLOAD_SIGNED_PDF: 'downloadSignedPDF',
	SAVE_SUT_DATA: 'saveSUTData',
	REGISTER_USA_EPAY_CLIENT_DATA: 'registerUSAePayClientData',
	MAKE_CLIENT_PAYMENT: 'makeClientPayment',
	UPDATE_ENTITY_DATA: 'updateEntityData',
	UPDATE_ERP_CONFIG: 'updateERPConfig',
	UPDATE_UDF_CONFIG: 'updateUDFConfig',
	GET_DASHBOARD_DATA: 'getDashboardData',
	UPDATE_SETTINGS: 'updateSettings',
	UPDATE_ERP_UDF_Data: 'updateERPUdfData',
	IS_ERP_SESSION_ALIVE: 'isERPSessionAlive',
	IS_CRM_SESSION_ALIVE: 'isCRMSessionAlive',
	VERIFY_ERP_SETTINGS: 'verifyERPSettings',
	TEST_ERP_SETTINGS: 'testERPSettings',
	UPDATE_CONTACT_EMPLOYEES: 'update_contact_employees',
	GENERATE_CSV_FILE: 'generateCSVFile',
	DX_ADD_UPDATE_ENTITY_DATA: 'dxAddUpdateEntityData',
	SAVE_ITEM_ATTACHMENTS: 'saveItemAttachments',
	VERIFY_SUB_ACCOUNT: 'verifySubAccount',
	SUB_USER_SIGNUP: 'subUserSignup',
	SUB_USER_DELETE: 'subUserDelete',
	DELETE_SUPPORT_USER: 'deleteSupportUser',
	SEND_EMAIL: 'sendEmail',
	UPDATE_PAYMENT_PROFILE: 'updatePaymentProfile',
	SAVE_NOTIFICATION_SETTINGS: 'saveNotificationSettings',
	GET_CURRENT_PLAN_DETAILS: 'getCurrentPlanDetails',
	EDIT_COMPANY_DETAILS: 'editCompanyDetails',
	IS_DOCUSIGNESSION_ALIVE: 'isDocusignessionAlive',
	SET_ADMIN_CONFIG: 'setConfig',
	SET_ZOHO_CONFIG: 'setZohoConfig',
	UPDATE_PAYMENT_TYPE_SETTINGS: 'updatePaymentTypeSettings',
	UPDATE_COMPANY_USERS: 'updateCompanyUsers',
	UPDATE_LICENSE_DATA: 'updateLicenseData',
	CALCULATE_TAX_RATES: 'calculateTaxRates',
	ASSIGN_SUPPORT_USER: 'assignSupportUser',
	LIST_SUPPORT_USER: 'listSupportUser',
	MAKE_ACH_PAYMENT: 'makeACHPayment',
	GET_CRON_SETTINGS: 'getCronSettings',
	SET_CRON_SETTINGS: 'setCronSettings',
	DELETE_DEVICE: 'removeDevice',
	DELETE_DEVICE_MAPPING: 'deleteDeviceMapping',
	DELETE_APP_VERSION: 'deleteAppVersion',
	UPDATE_CURRENT_SETTELEMENT_PERIOD: 'updateCurrentSettelementPeriod',
	GENERATE_CREDIT_MEMO_ON_PAST_INVOICES: 'generateCreditMemoOnPastInvoices',
	TEST_ENVELOPE_REQUEST: 'testEnvelopeRequest',
	CHECK_TEST_CONNECTION_STATUS: 'checkTestConnectionStatus',
	GET_CLOSE_SETTLEMENTS: 'getCloseSettlements',
	GET_ERP_CONFIG: 'getErpConfig',
	GET_CRM_CONFIG: 'getCrmConfig',
	CREATE_CONTRACT_FOR_USERS: 'createContractForUsers',
	SAVE_MULTIPLE_USER_SETTINGS: 'saveMultipleUserSettings',
	MASTER_MODULE_INTEGRATION: 'masterModuleIntegration',
	MASTER_USER_TYPE_INTEGRATION: 'masterUserTypeIntegration',
	TIER_SUBSCRIPTION: 'tiersubscription',
	GENERATE_INVOICES: 'generateInvoices',
	DELETE_INVOICE: 'deleteInvoice',
	RESEND_VERIFICATION_EMAIL: 'resendVerificationEmail',
	GET_ACTIVE_ADMIN_USERS: 'getactiveadminusers',
	DELETE_ASSIGN_COST: 'deleteAssignCost',
	IMPORT_CONTRACT_DATA: 'importContractData',
	DOWNLOAD_CONTRACT_STATEMENT: 'downloadContractStatement',
	VERIFY_USER_TOKEN: 'verifyUserToken',
	INVOICES_PENDING_ITEMS_WITH_VIRTUAL: 'invoicesPendingItemsWithVirtual',
	ADD_VIRTUAL_INVOICE: 'addVirtualInvoice',
	CHANGE_PASSWORD: 'changePassword',
	EDIT_PROFILE: 'editProfile',
	GENERATE_CERTIFICATE_FOR_NETSUITE_M2M: 'generateCertificateForNetsuiteM2M',
	VALIDATE_CLAIM_DATA: 'validateClaimData',
	CLAIM_CHARGEBACK_WITH_CSV: 'claimChargebackWithCsv',
	UPDATE_MULTIPLE_OPEN_SETTELEMENT_PERIOD: 'updateMultipleOpenSettelementPeriod',
	ADD_CUSTOMER_IN_CONTRACTS: 'addCustomerInContracts',
	CLOSE_CURRENT_SETTELEMENT: 'closeCurrentSettelement',
	GET_AP_INVOICE_HTML: 'getApInvoiceHtml',
	ADD_SUPPORT_USER: 'addSupportUser',
	EDIT_SUPPORT_USER: 'editSupportUser',
	REMOVE_DEVICE: 'removeDevice',
	VALIDATE_PRICELIST_ITEMS_DATA: 'validatePricelistItemsData',
	UPDATE_USER_TIER_DETAILS: 'updateUserTierDetails',
	GET_PAYMENT_INFO: 'getPaymentInfo',
	REGENERATE_INVOICE: 'regenerateInvoice',
	CANCEL_INVOICE: 'cancelInvoice',
	GET_SYNC_STATUS: 'getSyncStatus',
	SIGN_UP: 'signUp',
	GET_TERMS_AND_CONDITIONS: 'getTermsAndConditions',
	GET_DISPOSABLE_EMAIL: 'getDisposableEmail',
	VALIDATE_INVOICE_DATA: 'validateInvoiceData',
	IMPORT_VIRTUAL_INVOICES: 'importVirtualInvoices',
	MIN_MAX_CLAIM_PREVIEW: 'minMaxClaimPreview',
	SEND_PRICELIST_FOR_APPROVAL: 'sendPricelistForApproval',
	SEND_MULTI_CLAIM_TO_APPROVAL: 'sendMultiClaimTOApproval',
	OVERRIDE_ITEMS_FOR_CLAIM: 'overrideItemsForClaim',
	GET_CHARGEBACK_DOCUMENTS: 'getChargebackDocuments',
	IX_UPDATE_ENTITY_DATA: 'ixUpdateEntityData',
	GET_NEW_PENDING_APPROVALS: 'getNewPendingApprovals',
	SAVE_MANUAL_ADJUSTMENT: 'saveManualAdjustment',
	ADD_MANUAL_ADJUSTMENT_ITEMS: 'addManualAdjustmentItems',
	SET_ZOHO_BILLING_CONFIG: 'setZohoBillingConfig',
	SEND_IX_INVOICE: 'sendIXInvoice',
	DOWNLOAD_IX_INVOICE_PDF: 'downloadIXInvoicePDF',
};

export const ServerEntity = {
	USER_SETTINGS: 'userSettings',
	PROFIT: 'profit',
	REVENUE: 'revenue',
	INVOICE: 'invoice',
	ORDER: 'order',
	VARIETY: 'variety',
	VOLUME: 'volume',
	MARGIN: 'margin',
	REBATES: 'rebates',
	CHARGEBACKS: 'chargebacks',
	CUSTOMERS_TILES_DATA: 'customersTilesData',
	QUOTATIONS: 'quotations',
	ORDERS: 'orders',
	ITEMS: 'items',
	PRICELISTS: 'pricelists',
	ITEM_DETAILS: 'item_details',
	SHIPPINGTYPES_MAPPING: 'shippingtypes_mapping',
	IX_SHIPPINGTYPES: 'ix_shippingtypes',
	DEVICEMAPPINGS: 'device_mappings',
	APPVERSION: 'appversions',
	IX_CREDITCARDTYPES: 'ix_creditcard_types',
	SHIPPINGTYPE_DETAILS: 'shippingtype_details',
	BUYINGGROUPS: 'buyinggroups',
	VENDORS: 'vendors',
	REBATESCONTRACTS: 'rebatescontracts',
	REBATE_CHART_DATA: 'rebateChartData',
	REBATE_REVENUE_DATA: 'rebateRevenueData',
	UOM: 'uom',
	UOMGROUPS: 'uomgroups',
	DYNAMIC_PROPERTY_GROUPS: 'dynamicPropertyGroups',
	DYNAMIC_PROPERTIES: 'dynamicProperties',
	DYNAMIC_PROPERTY_OPTIONS: 'dynamicPropertyOptions',
	IX_COLORS: 'ixColors',
	DISTRIBUTION_CHARGEBACK_TYPES: 'distributionChargebackTypes',
	DISTRIBUTION_CHARGEBACK_CONTRACTS: 'distributionchargebackcontracts',
	CUSTOMERS: 'customers',
	APINVOICES: 'apinvoices',
	CLIENTS: 'clients',
	SUBUSERS: 'subusers',
	ITEM_CONFIGURATIONS: 'itemConfigurations',
	WAREHOUSES: 'warehouses',
	ROLES: 'roles',
	USERGROUP_DETAILS: 'usergroup_details',
	BILLINGINVOICE: 'billinginvoice',
	INCOMINGPAYMENTS: 'incomingpayments',
	DISTRIBUTION_CHARGEBACK_CLAIMS: 'distributionChargebackClaims',
	DRILLDOWN_CHART: 'drilldownChart',
	GET_ADMIN_CONFIG: 'getadminconfig',
	BILLBACKCONTRACTS: 'billbackcontracts',
	CHARGEBACKCONTRACTS: 'chargebackcontracts',
	MINMAXENDUSERSCHARGEBACKCONTRACTS: 'minmaxenduserschargebackcontracts',
	BILLBACK_CHARGEBACK_CONTRACTS: 'billback_chargeback_contracts',
	ENDUSERSCHARGEBACKCONTRACTS_DETAILS: 'enduserschargebackcontracts_details',
	CHARGEBACKCONTRACTS_DETAILS: 'chargebackcontracts_details',
	MINMAXENDUSERSCHARGEBACKCONTRACTS_DETAILS: 'minmaxenduserschargebackcontracts_details',
	GETINCENTXUSERS: 'getincentxusers',
	GET_DEVICE_LIST: 'deviceList',
	IX_CLASSIFICATIONS: 'ix_classifications',
	AP_INVOICE_REPORT: 'apInvoiceReport',
	ARCREDITMEMO: 'arcreditmemo',
	CHARGEBACKDOC: 'chargebackdoc',
	TERRITORIES: 'territories',
	JOURNALENTRIES: 'journalentries',
	CREDITCARDS: 'creditcards',
	INVOICES: 'invoices',
	CUSTOMERS_DRILL_DOWN_DATA: 'customersDrillDownData',
	APCREDITMEMO: 'apcreditmemo',
	COMPANY_360_CHART: 'company360Chart',
	DASHBOARD_SALES_CHART: 'dashboardSalesChart',
	COMMISSIONCONTRACTS: 'commissioncontracts',
	CLOSE_SETTLEMENTS: 'closesettlements',
	TRADEPROMOTION: 'tradepromotion',
	CCTEMPLATES: 'cctemplates',
	CRMDATA: 'crmdata',
	INCETIVES_UDF_CONFIG: 'incetivesUDFConfig',
	INCETIVES_UDF_DATA: 'incetivesudfdata',
	REBATES_UDF_CONFIG: 'rebatesUDFConfig',
	SEARCH_TRANSACTION_CONFIG: 'search_transaction_config',
	REBATES_UDF_DATA: 'rebatesudfdata',
	GET_INCENTX_INVOICES: 'get_incentx_invoices',
	GET_MASTER_MODULES: 'get_master_modules',
	GET_USER_TYPES: 'get_user_types',
	ENDUSERS: 'endusers',
	ENDUSER_INVOICES: 'enduserinvoices',
	ENDUSER_CREDIT_MEMOS: 'endusercreditmemos',
	ENDUSER_CHARGEBACK_DOCS: 'enduserChargebackDocs',
	END_USERS_TILES_DATA: 'endusersTilesData',
	USER_TYPES: 'userTypes',
	SALES_PPL: 'salesPpl',
	TIERS: 'tiers',
	IX_DASHBOARD_CONFIG: 'ix_dashboard_config',
	INCENTX_ENTITY_MASTER: 'ix_entity_master',
	ENTITY_MASTER: 'entity_master',
	PROFIT_ON_DOCUMENT_HEADER_UDF_CONFIG: 'profitOnDocumentHeaderUDFConfig',
	PROFIT_ON_DOCUMENT_HEADER: 'profitondocumentheader',
	FEATURE_CUSTOM_FIELDS: 'feature_custom_fields',
	ERP_CONFIG: 'erp_config',
	INVOICEINCENTIVES: 'invoiceincentives',
	ORDERINCENTIVES: 'orderincentives',
	ROYALTIESCONTRACTS: 'royaltiescontracts',
	NOTIFICATIONS: 'notifications',
	NOTIFICATION_TYPES: 'notificationtypes',
	TASKS: 'tasks',
	WORKFLOW_FIELDS: 'workflowfields',
	WORKFLOW_DOC_TYPES: 'workflowdoctypes',
	WORKFLOWTYPES: 'workflowtypes',
	WORKFLOWS: 'workflows',
	ITEM_CLAIM_DETAILS: 'item_claim_details',
	USERGROUPS: 'usergroups',
	SETTLEMENTREPORT: 'settlementreport',
	LEADS: 'leads',
	PROSPECTS: 'prospects',
	REPORTS: 'reports',
	COMMENTTRAIL: 'commenttrail',
	NOTIFICATION_HEADER: 'notification_header',
	CLOSESETTLEMENTS: 'closesettlements',
	SUPPORTUSERS: 'supportusers',
	GETADMINUSERS: 'getadminusers',
	CURRENCY: 'currency',
	LANGUAGES: 'languages',
	BUYING_GROUP_MEMBERS_WITH_INDIVIDUAL_REBATE: 'buyingGroupMembersWithIndividualRebate',
	TAXLEVELS: 'taxlevels',
	APPROVALTRAIL: 'approvaltrail',
	APPROVALS: 'approvals',
	APPROVALCLAIMSLIST: 'approvalClaimsList',
	PRICELIST_TERMS: 'pricelist_terms',
	CONTRACTSREPORTS: 'contractsreports',
	AUDITLOG_ENTITIES: 'auditlogEntities',
	AUDITLOG: 'auditlog',
	ENDUSER_INVOICE_DETAIL_REPORT: 'enduserInvoiceDetailReport',
	INVOICE_DETAIL_REPORT: 'invoiceDetailReport',
	GET_NEW_PENDING_APPROVALS: 'getNewPendingApprovals',
	ENDUSERS_WITH_SHIPPING_DETAILS: 'endusersWithShippingDetails',
	INVOICE_DETAIL_REPORT_DOWNLOAD: 'invoiceDetailReportDownload',
	APPROVAL_TRAIL_LOGS: 'approvalTrailLogs',
	COMPANIES_FOR_DYNAMIC_BUSINESS_CENTRAL: 'companiesForDynamicBusinessCentral',
	ALL_INVOICE_ZOHO_BILLING: 'all_invoice_zoho_billing',
	IX_UPLOADEDFILES: 'ix_uploadedfiles',
	INVOICE_DETAILS_ZOHO_BILLING: 'invoice_details_zoho_billing',
	CARD_INFORMATION_ZOHO_BILLING: 'card_information_zoho_billing',
	CUSTOMER_SUBSCRIPTIONS_ZOHO_BILLING: 'customer_subscriptions_zoho_billing',
	INVOICE_ZOHO_BILLING: 'invoice_zoho_billing',
	CUSTOMER_SUBSCRIPTIONS_DETAILS_ZOHO_BILLING: 'customer_subscriptions_details_zoho_billing',
	BANK_CARD_LIST_ZOHO_BILLING: 'bank_card_list_zoho_billing',
	GENERATESTATEMENT: 'generateStatement',
};

export const ServerSections = {
	COMMISSION_TILE: 'commissionTile',
	ROYALTY_TILE: 'royaltyTile',
	REBATE_TILE: 'rebateTile',
	INVOICES: 'invoices',
	CHANGE_ORDERS: 'changeOrders',
	ITEMS: 'items',
	DASHBOARD_SETTINGS: 'dashboard_settings',
	INVOICES_TILE: 'invoicesTile',
	ORDERS_TILE: 'ordersTile',
	GLOBAL_SETTINGS: 'global_settings',
	ACTIVE_CUSTOMERS_TILE: 'activeCustomersTile',
	OPEN_LEADS_TILE: 'openLeadsTile',
	OPEN_PROSPECTS_TILE: 'openProspectsTile',
	OPEN_TASKS_TILE: 'openTasksTile',
	QUOTATIONS_TILE: 'quotationsTile',
	AR_CREDITMEMOS_TILE: 'arCreditMemosTile',
	COMMISSION_SETTLEMENT_REPORT_SETTING: 'commission_settlement_report_setting',
	REBATE_CREDIT_MEMO_lIST_SETTING: 'rebate_credit_memo_list_setting',
	ROYALTIES_SETTLEMENT_REPORT_SETTING: 'royalties_settlement_report_setting',
	NOTIFICATIONS: 'notifications',
	CLAIM_APPROVE_NOTIFICATIONS: 'claim_approve_notifications',
	CLAIM_APPROVE_PRICELIST: 'claim_approve_pricelist',
	PRICELIST_APPROVE_NOTIFICATIONS: 'pricelist_approve_notifications',
	REBATE_SETTLEMENTS_APPROVE_NOTIFICATIONS: 'rebate_settlements_approve_notifications',
	MIN_MAX_CHARGEBACK_CLAIM_APPROVE_NOTIFICATION: 'min_max_chargeback_claim_approve_notification',
	MIN_MAX_CHARGEBACK_CLAIM_APPROVE_NOTIFICATION_DETAILS: 'min_max_chargeback_claim_approve_notification_details',
	EXTRA_COMMISSION_BASED_RULES: 'extraCommissionBasedRules',
	AMORTIZED_PAYMENTS: 'amortizedPayments',
	AR_CREDIT_MEMOS: 'arcreditmemos',
	SERVICE_AR_CREDIT_MEMOS: 'serviceArcreditmemos',
	MISCELLANEOUS_DEDUCTION_RULES: 'miscellaneousDeductionRules',
	CHANGE_INVOICES: 'changeInvoices',
	// CRM Menu
	CRM_LISTING_SETTING: 'crm_listing_setting',
	CRM_LEADS_LISTING: 'crm_leads_listing',
	CRM_PROSPECTS_LISTING: 'crm_prospects_listing',
	CRM_CUSTOMERS_LISTING: 'crm_customers_listing',
	CRM_TASKS_LISTING: 'crm_tasks_listing',
	CRM_CLIENTS_CUSTOMER360: 'crm_clients_customer360',

	CRM_LEAD_DETAILS_LISTING_SETTING: 'crm_lead_details_listing_setting',
	CRM_LEAD_DETAILS_TASKS_LISTING: 'crm_lead_details_tasks_listing',

	CRM_PROSPECT_DETAILS_LISTING_SETTING: 'crm_prospect_details_listing_setting',
	CRM_PROSPECT_DETAILS_QUOTES_LISTING: 'crm_prospect_details_quotes_listing',
	CRM_PROSPECT_DETAILS_ORDERS_LISTING: 'crm_prospect_details_orders_listing',
	CRM_PROSPECT_DETAILS_INVOICES_LISTING: 'crm_prospect_details_invoices_listing',
	CRM_PROSPECT_DETAILS_TASKS_LISTING: 'crm_prospect_details_tasks_listing',

	CRM_CUSTOMER_DETAILS_LISTING_SETTING: 'crm_customer_details_listing_setting',
	CRM_CUSTOMER_DETAILS_DELIVERY_NOTES_LISTING: 'crm_customer_details_delivery_notes_listing',
	CRM_CUSTOMER_DETAILS_REBATES_LISTING: 'crm_customer_details_rebates_listing',
	CRM_CUSTOMER_DETAILS_QUOTES_LISTING: 'crm_customer_details_quotes_listing',
	CRM_CUSTOMER_DETAILS_ORDERS_LISTING: 'crm_customer_details_orders_listing',
	CRM_CUSTOMER_DETAILS_INVOICES_LISTING: 'crm_customer_details_invoices_listing',
	CRM_CUSTOMER_DETAILS_TASKS_LISTING: 'crm_customer_details_tasks_listing',
	CRM_CUSTOMER_DETAILS_CONTACTS_LISTING: 'crm_customer_details_contacts_listing',
	CRM_CUSTOMER_DETAILS_ADDRESSES_LISTING: 'crm_customer_details_addresses_listing',
	CRM_CUSTOMER_DETAILS_AR_CREDIT_MEMOS_LISTING: 'crm_customer_details_ar_credit_memos_listing',
	CRM_CUSTOMER_DETAILS_BILLBACK_CHARGEBACK_LISTING: 'crm_customer_details_billback_chargeback_listing',

	// Sales Menu 
	SALES_LISTING_SETTING: 'sales_listing_setting',
	SALES_QUOTES_LISTING: 'sales_quotes_listing',
	SALES_ORDERS_LISTING: 'sales_orders_listing',
	SALES_INVOICES_LISTING_SETTING: 'sales_invoices_listing_setting',
	SALES_AR_CREDIT_MEMOS_LISTING: 'sales_ar_credit_memos_listing',
	SALES_DISTRIBUTION_CHARGEBACKS_LISTING: 'sales_distribution_chargebacks_listing',
	SALES_INCOMING_PAYMENTS_LISTING: 'sales_incoming_payments_listing',
	SALES_DELIVERY_NOTES_LISTING: 'sales_delivery_notes_listing',
	SALES_END_USER_INVOICES_LISTING: 'sales_end_user_invoices_listing',
	SALES_END_USER_INVOICES_VIEW_DETAILS_LISTING: 'sales_end_user_invoices_view_details_listing',
	SALES_END_USER_CHARGEBACKS_LISTING: 'sales_end_user_chargebacks_listing',

	// Company Menu 
	COMPANY_LISTING_SETTING: 'company_listing_setting',
	COMPANY_VENDORS_LISTING: 'company_vendors_listing',
	COMPANY_BUYING_GROUPS_LISTING: 'company_buying_groups_listing',
	COMPANY_END_USERS_LISTING: 'company_end_users_listing',
	COMPANY_END_USER_INVOICES_LISTING: 'company_end_user_invoices_listing',
	COMPANY_END_USER_INVOICES_VIEW_DETAILS_LISTING: 'company_end_user_invoices_view_details_listing',
	COMPANY_VENDOR_DETAILS_AP_INVOICES_LISTING: 'company_vendor_details_ap_invoices_listing',
	COMPANY_END_USER_CREDIT_MEMOS_LISTING: 'company_end_user_credit_memos_listing',
	COMPANY_END_USERS_ADDRESSES_LISTING: 'company_end_users_addresses_listing',

	// Purchase Menu
	PURCHASES_LISTING_SETTING: 'purchases_listing_setting',
	PURCHASES_AP_INVOICES_LISTING: 'purchases_ap_invoices_listing',
	PURCHASES_AP_CREDIT_MEMOS_LISTING: 'purchases_ap_credit_memos_listing',

	// Users Management Menu
	USERS_MANAGEMENT_LISTING_SETTING: 'users_management_listing_setting',
	USERS_MANGEMENT_USERS_LISTING: 'users_mangement_users_listing',

	// Items Menu
	PRODUCTS_LISTING_SETTING: 'products_listing_setting',
	PRODUCTS_ITEMS_LISTING: 'products_items_listing',
	PRODUCTS_PRICES_LISTING: 'products_prices_listing',

	// Incentive Menu
	INCENTIVES_LISTING_SETTING: 'incentives_listing_setting',
	INCENTIVES_REBATES_LISTING: 'incentives_rebates_listing',
	INCENTIVES_AGREEMENT_TEMPLATES_LISTING: 'incentives_agreement_templates_listing',
	INCENTIVES_COMMISSION_AGREEMENTS_LISTING: 'incentives_commission_agreements_listing',
	INCENTIVES_BILLBACK_CHARGEBACK_LISTING: 'incentives_billback_chargeback_listing',
	INCENTIVES_ROYALTIES_LISTING: 'incentives_royalties_listing',
	ITEM_CLAIM_DETAILS_SETTING: 'item_claim_details_setting',
	INCENTIVES_END_USERS_CHARGEBACK: 'incentives_end_users_chargeback',
	MIN_MAX_CHARGEBACK_CONTRACT: 'min_max_chargeback_contract',
	MIN_MAX_CHARGEBACK_CLAIM_PREVIEW: 'min_max_chargeback_claim_preview',
	MIN_MAX_CHARGEBACK_OTHER_PURCHASE_LST: 'min_max_chargeback_other_purchase_lst',
	CLAIM_PREVIEW_SUCCEED_CLAIM_TAB: 'claim_preview_succeed_claim_tab',
	CLAIM_PREVIEW_IGNORED_CLAIM_TAB: 'claim_preview_ignored_claim_tab',
	MANUAL_ADJUSTMENT_CLAIM: 'manual_adjustment_claim',
	MANUAL_ADJUSTMENT_IGNORED_CLAIM_TAB: 'manual_adjustment_ignored_claim_tab',
	EUCC_VIEW_CHARGEBACK_DOCUMENT: 'eucc_view_chargeback_document',
	INCENTIVES_COMMISSION_CONTRACT_LIST: 'incentives_commission_contract_list',
	CLOSE_MULTIPLE_SETTLEMENT_COMMISSION: 'close_multiple_settlement_commission',
	MIN_MAX_CHARGEBACK_MULTIPLE_CLAIM_APPROVAL: 'min_max_chargeback_multiple_claim_approval',
	INCENTIVES_TRADE_PROMOTION_LISTING: 'incentives_trade_promotion_listing',

	INCENTIVES_REBATES_DETAILS_INDIVIDUAL: 'incentives_rebates_details_individual',
	INCENTIVES_REBATES_DETAILS_BUYINGGROUP: 'incentives_rebates_details_buyinggroup',
	INCENTIVES_REBATES_DETAILS_GROWTH_INCENTIVE: 'incentives_rebates_details_growth_incentive',
	INCENTIVES_REBATES_DETAILS_DISTRIBUTION: 'incentives_rebates_details_distribution',
	INCENTIVES_REBATES_DETAILS_DISTRIBUTION_DIFFERENTIAL: 'incentives_rebates_details_distribution_differential',

	// Reports Menu
	REPORTS_LISTING_SETTING: 'reports_listing_setting',
	REPORTS_INCENTIVES_REPORTS_LISTING: 'reports_incentives_reports_listing',
};

export const ServerMode = {
	HEADER: 'header',
	ENDUSERS: 'endusers',
	CUSTOMERS: 'customers',
	ITEMS: 'items',
}

export const ServerAction = {
	ADD: 'add',
	EDIT: 'edit',
	DELETE: 'delete',
}

export const GlobalPopoupWidth100Ratio = 100; /* Will be calculate in percentage */
export const GlobalPopoupWidth90Ratio = 90; /* Will be calculate in percentage */
export const GlobalPopoupWidth80Ratio = 80; /* Will be calculate in percentage */
export const GlobalPopoupWidth70Ratio = 70; /* Will be calculate in percentage */
export const GlobalPopoupWidth60Ratio = 60; /* Will be calculate in percentage */
export const GlobalPopoupWidth40Ratio = 40; /* Will be calculate in percentage */
export const GlobalPopoupWidth20Ratio = 20; /* Will be calculate in percentage */
export const GlobalPopoupWidth30Ratio = 30; /* Will be calculate in percentage */
export const GlobalPopoupHeight100Ratio = 100; /* Will be calculate in percentage */
export const GlobalPopoupHeight90Ratio = 90; /* Will be calculate in percentage */
export const GlobalPopoupHeight95Ratio = 95; /* Will be calculate in percentage */
export const GlobalPopoupHeight80Ratio = 80; /* Will be calculate in percentage */
export const GlobalPopoupHeight70Ratio = 70; /* Will be calculate in percentage */
export const GlobalPopoupHeight60Ratio = 60; /* Will be calculate in percentage */
export const GlobalPopoupHeight40Ratio = 40; /* Will be calculate in percentage */
export const GlobalPopoupHeight20Ratio = 20; /* Will be calculate in percentage */
